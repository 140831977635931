import { useState, useEffect } from "react";
import styles from "./Accordion.module.css";

export const Accordion = ({ items, clickindex, onAccordionClick }) => {
  const [active, setActive] = useState(clickindex);

  useEffect(() => {
    setActive(clickindex);
  }, [clickindex]);

  const handleToggle = (index) => {
    setActive(index);
    onAccordionClick(index);
  };

  return (
    <section className={styles.image}>
      {items.map((item, index) => {
        const isActive = active === index ? styles.active : "";
        return (
          <div
            key={index}
            className={`${styles.item} ${isActive}`}
            onClick={() => handleToggle(index)}
          >
            {item.image ? (
              <img src={item.image} alt="media" className={styles.img} />
            ) : (
              <video controls className={styles.video}>
                <source src={item.video} type="video/mp4" />
                Votre navigateur ne supporte pas la vidéo.
              </video>
            )}
            <div className={styles.content}>
              <div>
                <h2>{item.header}</h2>
                <p>{item.text}</p>
              </div>
            </div>
          </div>
        );
      })}
    </section>
  );
};
