import { useState, useEffect, useRef } from "react";
import { Navbar, Nav, Container } from "react-bootstrap";
import {NavLink } from "react-router-dom";
import logo from '../Assets/img/logo.png';
import camion_logo from '../Assets/img/camion_logo.png';
import './NavBar.css';
import { useLocation } from 'react-router-dom';




export const NavBar = () => {
  const menuRef = useRef();
  const [menuOpen, setMenuOpen] = useState(false);
  const [scrolled, setScrolled] = useState(false);



  const location = useLocation();

  useEffect(() => {
    setMenuOpen(false);
  }, [location]);
  
  useEffect(() => {
    const onScroll = () => {
      if (window.scrollY > 50) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    }

    window.addEventListener("scroll", onScroll);

    return () => window.removeEventListener("scroll", onScroll);
  }, [])

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setMenuOpen(false);
      }
    };
  
    if (menuOpen) {
      document.addEventListener('mousedown', handleOutsideClick);
    }
  
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, [menuOpen]);

  return (
      <Navbar expand="md" className={scrolled ? "scrolled" : ""}>
        <Container>
          <img id="camion"src={camion_logo} alt="Logo"/>
          <img id="logo"src={logo} alt="Logo"/>
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ms-auto">
            <div className="menu" onClick={() => setMenuOpen(!menuOpen)} ref={menuRef}>
              <span onClick={() => setMenuOpen(!menuOpen)}></span>
              <span onClick={() => setMenuOpen(!menuOpen)}></span>
              <span onClick={() => setMenuOpen(!menuOpen)}></span>
            </div>
            <ul className={menuOpen ? "open" : ""} ref={menuRef}>
              <li>
                  <NavLink to="/"onClick={() => window.scrollTo(0, 0)}>ACCUEIL</NavLink>
                </li>
                <li>
                  <NavLink to="/About"onClick={() => window.scrollTo(0, 0)}>QUI SOMMES-NOUS?</NavLink>
                </li>
                <li>
                  <NavLink to="/Services"onClick={() => window.scrollTo(0, 0)}>NOS SERVICES</NavLink>
                </li>
                <li>
                  <NavLink to="/Infrastructures"onClick={() => window.scrollTo(0, 0)}>NOS EQUIPEMENTS</NavLink>
                </li>
                <li>
                  <NavLink to="/Contact"onClick={() => window.scrollTo(0, 0)}>CONTACT</NavLink>
                </li>
                
              </ul>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
  )
}
