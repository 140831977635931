import { Container, Row, Col } from "react-bootstrap";
import {NavLink } from "react-router-dom";
import { Link } from 'react-router-dom';
import styles from './Footer.module.css';
import logo from "../Assets/img/logo.png";
import camion_logo from '../Assets/img/camion_logo.png';


export const Footer = () => {
  return (
    <footer className={styles.footer}>
      
      <div className={styles.degrade}>
        <Link to="/">
          <img id={styles.camionfooter} src={camion_logo} alt="Logo" onClick={() => window.scrollTo(0, 0)}/>
          <img id={styles.logofooter} src={logo} alt="Logo" onClick={() => window.scrollTo(0, 0)}/>
        </Link>
      </div>
      <Container className={styles.container}>
        <Row className="align-items-center">
          <Col size={12} sm={6}>
          </Col>
          <Col size={12} sm={6} className="text-center text-sm-end">
            
            <div className={styles.links} style={{display: "flex", justifyContent: 'center', flexDirection: 'row'}}>
              
              <NavLink to="/"onClick={() => window.scrollTo(0, 0)}>Accueil</NavLink>
              <NavLink to="/About"onClick={() => window.scrollTo(0, 0)}>Qui sommes-nous?</NavLink>
              <NavLink to="/Services"onClick={() => window.scrollTo(0, 0)}>Nos Services</NavLink>
              <NavLink to="/Infrastructures"onClick={() => window.scrollTo(0, 0)}>Nos equipements</NavLink>
              <NavLink to="/Contact"onClick={() => window.scrollTo(0, 0)}>Contact</NavLink>
            </div>
          </Col>
        </Row>
      </Container>
        <div className={styles.copyright}>
        <NavLink to="/Mentions-legales"onClick={() => window.scrollTo(0, 0)}> Mentions légales</NavLink>

        <p>Copyright 2024. Tous droits réservés.</p>
        </div>
    </footer>
  )
}
