import { useState, useEffect } from "react";
import styles from './About.module.css';
// eslint-disable-next-line
import vid1 from "../../Assets/vid/vid1.mp4";
import vid2 from "../../Assets/vid/vid2.mp4";
import N1 from "../../Assets/img/N1.png";
import flotte from "../../Assets/img/flotte.jpg";



export const About = () => {
  const [scrollPosition, setScrollPosition] = useState(0);

  const handleScroll = () => setScrollPosition(window.pageYOffset);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  
  const isMobile = window.innerWidth <= 380;
  const isTablet = window.innerWidth <= 768;
  const isLargePc = window.innerWidth >= 1024;

  const factor = isMobile ? 2 : isTablet ? 1.5 : 1;

  const handleButtonClick = () => {
    const headingElement = document.querySelector("p");
    headingElement.scrollIntoView({ behavior: "smooth" });
  };
  
  return (
    <>
      <section
        style={{
          backgroundSize: `${(100 + scrollPosition/40 )* factor}%`,
        }}
        className={styles.banner}
      >
        <button className={styles.h2} onClick={handleButtonClick}>L'histoire de Transports Lopes SA</button>
        <div className={styles.arrow2} onClick={handleButtonClick}></div>
      </section>
      <section className={styles.about}>
        <section className={styles.texte}>
          <p>
          &nbsp;&nbsp; En 2008, M. Lopes a fondé une entreprise individuelle spécialisée dans les services de transport et de terrassement. Initialement, il gérait personnellement les tâches de transport avec un seul camion de couleur rouge et bronze.
          </p>
            <div className={styles.n1}>
              <img  src={N1} alt="n1"></img>
              Transports Lopes - Premier camion
            </div>
          <p>
          Deux ans plus tard, en 2010, il a fait une importante acquisition pour sa flotte en achetant un nouveau camion vert vif, le premier du genre pour son entreprise, baptisé "Transports Lopes - NR 1". Cela a marqué une avancée significative dans la croissance de son entreprise. L'année suivante, en 2011, Transports Lopes a subi une transformation, passant d'une entreprise individuelle à une société anonyme sous le nom de Transports Lopes SA. Cette évolution témoignait de l'expansion des opérations de l'entreprise et de son engagement à fournir des services de qualité supérieure. Aujourd'hui, l'entreprise dispose d'une flotte d'environ trente véhicules, ainsi que plusieurs machines de chantiers, bennes et remorques, un indicateur clair de sa croissance et de son succès.  </p>
          <div className={styles.n1}>
              <img  src={flotte} alt="flotte"></img>
              Transports Lopes SA - Flotte de véhicules
            </div>
          <p>
          Transports Lopes SA est activement engagée dans des activités de transport dans toute la Suisse. L'entreprise est spécialisée dans le transport de divers matériaux de construction, y compris le béton, l'asphalte et les débris d'excavation. En 2016, l'acquisition de la plus grande balayeuse de Suisse, suivie en 2018 par l'achat d'un camion-grue, puis d'un broyeur, et enfin de la toute dernière machine Hitachi ZX300, lui ont permis de disposer d'équipements de la plus haute qualité pour les terrassements et les démolitions. Ces investissements ont renforcé sa capacité à être un partenaire polyvalent et fiable pour tout projet de démolition et terrassement.
          </p>
          <p>
          Le siège social de l'entreprise est idéalement situé au Route de Divonne 50 B, Nyon. Cette localisation stratégique, facilement accessible depuis l'autoroute A1 entre Lausanne et Genève, garantit une livraison de services efficace et une grande commodité pour les clients.
          </p>
          <p>
          Transports Lopes SA est dédiée à offrir des services de haute qualité et est toujours prête à répondre aux besoins de ses clients. L'entreprise s'engage à étudier chaque projet en détail, dans le but de fournir les solutions les plus adaptées et les plus rentables. 
          </p>
        </section>
        <section className={styles.video}>
          <video width={isLargePc ? window.innerWidth/2 : window.innerWidth-(window.innerWidth*10)/100} height={isLargePc ? ((window.innerWidth*480)/768)/2:  ((window.innerWidth*480)/768)} controls>
            <source src={vid2} type="video/mp4" />
            Votre navigateur ne supporte pas la vidéo.
          </video>
        </section>
      </section>
    </>
  );
};
