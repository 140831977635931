import React from 'react'

export const Mentions = () => {
  return (
    <div style={{marginRight: '20vw', marginLeft: '20vw', marginTop: '15vw', display:'flex', flexDirection:'column', alignContent:'center', justifyContent: 'flex-start', height:'120vh'}}>
        <h1 style={{display: 'flex',flexDirection: 'row',justifyContent: 'center'}}>
        Mentions légales
        </h1>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <h2>Le site Internet Transports Lopes SA est publié par :</h2>
        <br></br>
        <br></br>
        <br></br>
        <h3>
        Transports Lopes SA<br></br>
        Route de Divonne 50 b<br></br>
        CH-1260 Nyon<br></br>
        Suisse<br></br><br></br>
        Email : info@transportslopes.ch
        </h3>
        <br></br>
        <h4 style={{textAlign: 'justify'}}>Transports Lopes SA détient et exploite ce site Internet. Toutes les marques, noms, titres, logos, images, graphismes, textes et autres éléments présents sur ce site sont la propriété de Transports Lopes SA ou de ses partenaires contractuels. Toute reproduction, transmission (électronique ou autre), modification, utilisation du site, ou ajout de liens vers celui-ci à des fins publiques ou commerciales est strictement interdite sans autorisation écrite préalable.</h4>
    </div>
  )
}
