import "./App.css";
import React, { Suspense } from "react";
import LazyLoad from 'react-lazyload';
import { Route, Routes } from "react-router-dom";
import { NavBar } from "./Components/NavBar";
import { Footer } from "./Components/Footer";
import { Accueil } from "./Components/Pages/Accueil";
import { About } from "./Components/Pages/About";
import { Services } from "./Components/Pages/Services";
import { Contact } from "./Components/Pages/Contact";
import { Infrastructures } from "./Components/Pages/Infrastructures";
import { Mentions } from "./Components/Pages/Mentions";
import { Galerie } from "./Components/Pages/Galerie";

import { useState, useEffect } from 'react';

function App() {


  return (
    <div className="App">
      <Suspense fallback={<div>Loading...</div>}>
      <LazyLoad once>
        <NavBar />
        <Routes>
          <Route path="/" element={<Accueil />} />
          <Route path="/About" element={<About />} />
          <Route path="/Infrastructures" element={<Infrastructures />} />
          <Route path="/Contact" element={<Contact />} />
          <Route path="/Services" element={<Services />} />
          <Route path="/Galerie" element={<Galerie />} />
          <Route path="/Mentions-legales" element={<Mentions />} />
        </Routes>
        <Footer />
        </LazyLoad>
      </Suspense>
    </div>
  );
}

export default App;
