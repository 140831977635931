import styles from "./Accueil.module.css";
import camion from "../../Assets/img/camion.jpg";
import camionDecoupe from "../../Assets/img/camion.png";
import vid from "../../Assets/vid/vid3.mp4";
import { useNavigate } from 'react-router-dom';

import { useState, useRef } from "react";

export const Accueil = () => {
  const [hover, setHover] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const navigate = useNavigate();

  const navigateToServices = () => {
    navigate('/Services');
    };
  const navigateToAbout = () => {
    navigate('/About');
    };
    const navigateToContact = () => {
        navigate('/Contact');
        };
  const videoRef = useRef(null);

  const handleMouseEnter = () => {
    setIsHovered(true);
    videoRef.current.pause();
  };

  const isMobile = window.innerWidth < 1024;

  const handleMouseLeave = (event) => {
    if (!videoRef.current.contains(event.relatedTarget)) {
      setIsHovered(false);
      videoRef.current.play();
    }
  };
  return (
    <section className={styles.accueil}>
      <div className={styles.bgDisplay}>
        <img
          className={hover ? styles.camionEntierOp : styles.camionEntier}
          src={camion}
          alt="camion complet"
        />
      </div>
      <div className={styles.bg}>
        <h1>
          <span className={styles.mot}>Votre</span>{" "}
          <span className={styles.mot}>partenaire</span>{" "}
          <span className={styles.mot}>en</span>{" "}
          <span className={styles.mot}>transports</span>,{" "}
          <span className={styles.mot}>démolitions</span>{" "}
          <span className={styles.mot}>et</span>{" "}
          <span className={styles.mot}>terrassements</span>.
        </h1>
        <img
          className={styles.camion}
          onMouseEnter={() => {
            setHover(true);
          }}
          onMouseLeave={() => {
            setHover(false);
          }}
          src={camionDecoupe}
          alt="camion"
        />
      </div>
      <div className={styles.body}>
        <div className={styles.entreprise} onClick={() => { window.scrollTo(0,0); navigateToAbout(); }}>
            <div className={styles.servicesText}>
                <h2>Notre entreprise</h2>
                <p>
                Transports Lopes SA, fondée en 2008, est passée d'une entreprise
                individuelle à une société anonyme en 2011, et dispose désormais
                d'une flotte de trente véhicules pour des services de transport,
                terrassement et démolition à travers la Suisse. Avec des équipements de pointe,
                l'entreprise s'engage à fournir des solutions de haute qualité et
                adaptées aux besoins de ses clients. Située à Nyon, elle garantit
                une efficacité et une commodité maximales.
                </p>
            </div>
            <div className={styles.arrowDiv}>
                <span className={styles.arrow}></span>{" "}
            </div>
        </div>
        <div className={styles.services} onClick={() => { window.scrollTo(0,0); navigateToServices();}}>
          <div className={styles.servicesText}>
            <h2>Nos services</h2>
            <p>
              Transports Lopes SA offre une gamme complète de services, y
              compris terrassements, démolitions, enrobés, transport de béton,
              services de balayeuse et de camion-grue. Avec une vaste
              sélection de véhicules et machines, nous répondons à tous vos
              besoins de transport et de chantier. Nos équipements de pointe
              garantissent des solutions fiables et efficaces pour chaque
              projet.
            </p>
          </div>
          <div className={styles.arrowDiv}>
            <span className={styles.arrow}></span>{" "}
          </div>
        </div>
      </div>
      <div className={styles.videoContainer} >
        <video
          ref={videoRef}
          autoPlay
          muted
          loop
          className={styles.video}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          onClick={() => { if (!isMobile) {window.scrollTo(0,0); navigateToContact(); }}} 
        >
          <source src={vid} type="video/mp4" />
          Votre navigateur ne supporte pas la vidéo.
        </video>
        <div className={styles.overlay} style={{ opacity: isHovered ? 1 : 0 }}>
          <h1>Contact</h1>
          <br />
          <p>
            <h4>Bureau</h4>
            <br />
            Tel: +41 21 601 42 11
            <br />
            E-mail: info@transportslopes.ch
            <br />
            <br />
            <h4>Directeur</h4>
            <br />
            M. LOPES Luis
            <br />
            Tel: +41 79 819 68 66
          </p>
        </div>
      </div>
    </section>
  );
};
