import React, { useRef,useState,useEffect } from 'react';
import { Accordion } from "../Accordion";
import styles from'./Infrastructures.module.css'
import img1 from "../../Assets/img/Balayeuse/bal6.jpg"
import img2 from "../../Assets/img/Balayeuse/bal9.jpg"
import img3 from "../../Assets/img/Balayeuse/bal7.jpg"
import img4 from "../../Assets/img/Balayeuse/bal8.jpg"
import img5 from "../../Assets/img/Balayeuse/bal1.jpg"
import img6 from "../../Assets/img/pelles/pelle2.jpg"
import img7 from "../../Assets/img/pelles/pelle3.jpg"
import img8 from "../../Assets/img/pelles/pelle6.jpg"
import img10 from "../../Assets/img/pelles/pelle8.jpg"
import img11 from "../../Assets/img/camions/cam12.jpg"
import img12 from "../../Assets/img/camions/cam14.jpg"
import img13 from "../../Assets/img/camions/cam6.png"
import img14 from "../../Assets/img/camions/cam15.jpg"
import img15 from "../../Assets/img/camions/cam11.jpg"
import img16 from "../../Assets/img/transports/tra4.jpg"
import img17 from "../../Assets/img/transports/tra6.jpg"
import img18 from "../../Assets/img/transports/tra3.jpg"
import img19 from "../../Assets/img/transports/tra5.jpg"
import img20 from "../../Assets/img/transports/tra7.jpg"
import img21 from "../../Assets/img/enrobes/enr8.jpg"
import img22 from "../../Assets/img/enrobes/enr2.jpeg"
import img23 from "../../Assets/img/enrobes/enr10.jpg"
import img24 from "../../Assets/img/enrobes/enr4.jpg"
import img25 from "../../Assets/img/enrobes/enr3.png"
import img26 from "../../Assets/img/grue/grue13.jpg"
import img27 from "../../Assets/img/grue/grue2.png"
import img28 from "../../Assets/img/grue/grue3.png"
import img29 from "../../Assets/img/grue/grue15.jpg"
import img30 from "../../Assets/img/grue/grue14.jpg"
import imgspec from "../../Assets/img/grue/spec.png"
import img31 from "../../Assets/img/broyeur/broy4.jpg"
import img32 from "../../Assets/img/broyeur/broy3.jpg"
import img33 from "../../Assets/img/broyeur/broy1.png"
import img36 from "../../Assets/img/bennes/benne1.png"
import img37 from "../../Assets/img/bennes/benne2.jpg"
import img40 from "../../Assets/img/bennes/benne3.jpg"
import vid from "../../Assets/vid/vid3.mp4"
import vid6 from "../../Assets/vid/vid6.mov"
import vid1 from "../../Assets/vid/vidB1.mov"
import vid2 from "../../Assets/vid/vidB2.mov"
import vid3 from "../../Assets/vid/vidB3.mov"

import arrow from "../../Assets/img/arrow.png"
import camions from "../../Assets/img/camions.jpg"




export const Infrastructures = () => {

    const [activeIndex, setActiveIndex] = useState(0);

    const handleViewSpecs = () => {
        setActiveIndex(items6.length - 1);
      };
      
    const handleAccordionClick = (index) => {
        setActiveIndex(index);
      };
    
    const accordionRef1 = useRef(null);
    const accordionRef2 = useRef(null);
    const accordionRef3 = useRef(null);
    const accordionRef4 = useRef(null);
    const accordionRef5 = useRef(null);
    const accordionRef6 = useRef(null);
    const accordionRef7 = useRef(null);
    const accordionRef8 = useRef(null);
    const accordionRef9 = useRef(null);
    const [isHovered, setIsHovered] = useState(false);

    const items = [{image: img5},{image: img2},{image: img3},{image: img4},{image: img1}];
    const items2 = [{image: img6},{image: img7},{image: img8},{video: vid6},{video: vid}];
    const items3 = [{image: img11},{image: img12},{image: img13},{image: img14},{image: img15}];
    const items4 = [{image: img16},{image: img17},{image: img18},{image: img19},{image: img20}];
    const items5 = [{image: img21},{image: img22},{image: img23},{image: img24},{image: img25}];
    const items6 = [{image: img26},{image: img27},{image: img28},{image: img29},{image: imgspec}];
    const items7 = [{video: vid1},{video: vid2},{video: vid3},{image: img31},{image: img32},{image: img33}];
    const items8 = [{image: img36},{image: img37},{image: img40}];

return (
    <section className={styles.infra}>
        <div className={styles.div}>
            <h2 style={{
                borderBottom: '3px solid #6cd632',
                paddingBottom: '5px'
            }}>Transports Lopes SA </h2>
            <h2 >possède une&nbsp; <span style={{
                backgroundColor: '#6bd6326e',
                borderRadius: '0.5rem'
            }}> vaste</span>&nbsp;séléction</h2>
            <h2 >de&nbsp;<span style={{
                backgroundColor: '#6bd6326e',
                borderRadius: '0.5rem'
            }}> machines</span> &nbsp;et de &nbsp;<span style={{
                backgroundColor: '#6bd6326e',
                borderRadius: '0.5rem'
            }}> véhicules</span> &nbsp;pour</h2>
            <h2 > répondre à vos &nbsp;<span style={{
                backgroundColor: '#6bd6326e',
                borderRadius: '0.5rem'
            }}> besoins</span>&nbsp;à tout moment.</h2>
        </div>
        
        <div className={styles.left}>
            <div className={styles.divarrowl}>
            <div className={styles.arrow2}  onClick={() => {
                const rect = accordionRef2.current.getBoundingClientRect();
                window.scrollTo({ 
                    top: rect.top + window.scrollY,
                    behavior: 'smooth' 
                });
            }}></div>
                <div className={styles.texte}>
                    <p><span style={{fontWeight: '400'}}>Balayeuse Hydrostatique</span> 3 essieux avec rotoplast 50 a 350 bars<br></br><span style={{fontSize: window.innerWidth <= 768 ? '2vw' : '1vw', fontWeight: '400', fontFamily: 'system-ui', }}>(Balaye, lave et aspire absolument tout)</span></p>
                </div>
            </div>
            <div ref={accordionRef1} >
            <Accordion items={items} clickindex={items.length - 1} onAccordionClick={handleAccordionClick}/>

            </div>
        </div>
        <div className={styles.right}>
            <div className={styles.divarrowr}>
            <div className={styles.arrow2}  onClick={() => {
                const rect = accordionRef3.current.getBoundingClientRect();
                window.scrollTo({ 
                    top: rect.top + window.scrollY,
                    behavior: 'smooth' 
                });
            }}></div>
                <div className={styles.texte}>
                <p><span style={{fontWeight: '400'}}>Pelles hydrauliques - Caterpillar/Hitachi</span><span style={{fontSize: window.innerWidth <= 768 ? '3vw' : '1vw', fontWeight: '400', fontFamily: 'system-ui', marginTop: '10px'}}> • ZX300LCN-7 - Hitachi<br></br>• 2.7 tonnes à chenilles - CAT 302.7DCR <br></br>• 13 tonnes a pneus - CAT M313D<br></br>• 9 tonnes à chenilles - CAT 308CR<br></br>• 20 tonnes à chenilles - CAT 315F<br></br>• 26 tonnes à chenilles - CAT 325F</span></p>
                </div>
            </div>
            <div ref={accordionRef2} >
            <Accordion items={items2} clickindex={0} onAccordionClick={handleAccordionClick}/>

            </div>
        </div>
        <div className={styles.left}>
            <div className={styles.divarrowl}>
                <div className={styles.arrow2}  onClick={() => {
                     const rect = accordionRef4.current.getBoundingClientRect();
                     window.scrollTo({ 
                         top: rect.top + window.scrollY,
                         behavior: 'smooth' 
                     });
                }}></div>
                <div className={styles.texte}>
                    <p><span style={{fontWeight: '400'}}>Véhicules de chantier</span><span style={{fontSize: window.innerWidth <= 768 ? '3vw' : '1vw', fontWeight: '400', fontFamily: 'system-ui', marginTop: '10px'}}> • Semi<br></br>• Basculant 4 essieux<br></br>•  Basculant 5 essieux<br></br>• Multilift 5 essieux <br></br>• Camion poire 5 essieux <br></br>• Toupie Malaxeur 5 essieux - 9m³ de charge utile</span></p>
                </div>
            </div>
            <div ref={accordionRef3} >
            <Accordion items={items3} clickindex={items.length - 1} onAccordionClick={handleAccordionClick}/>

            </div>
        </div>
        <div className={styles.right}>
            <div className={styles.divarrowr}>
                <div className={styles.arrow2}  onClick={() => {
                    const rect = accordionRef5.current.getBoundingClientRect();
                    window.scrollTo({ 
                        top: rect.top + window.scrollY,
                        behavior: 'smooth' 
                    });
                }}></div>
                <div className={styles.texte}>
                <p><span style={{fontWeight: '400'}}>Remorques</span><span style={{fontSize: window.innerWidth <= 768 ? '3vw' : '1vw', fontWeight: '400', fontFamily: 'system-ui', marginTop: '10px'}}> • Port-machine - 15 tonnes<br></br>• Semi-surbaissés - jusqu'a 65 tonnes de charge utile<br></br>• Remorques surbaissées - jusqu'a 25 tonnes de charge utile</span></p>
                </div>
            </div>
            <div ref={accordionRef4} >
            <Accordion items={items4} clickindex={0} onAccordionClick={handleAccordionClick}/>

            </div>
        </div>
        <div className={styles.left}>
            <div className={styles.divarrowl}>
                <div className={styles.arrow2}  onClick={() => {
                     const rect = accordionRef6.current.getBoundingClientRect();
                     window.scrollTo({ 
                         top: rect.top + window.scrollY,
                         behavior: 'smooth' 
                     });
                }}></div>
                <div className={styles.texte}>
                    <p><span style={{fontWeight: '400'}}>Véhicules thermos</span><span style={{fontSize: window.innerWidth <= 768 ? '3vw' : '1vw', fontWeight: '400', fontFamily: 'system-ui', marginTop: '10px'}}> • Thermos lift 4 essieux - 19 tonnes de charge utile<br></br>• Thermos 5 essieux - 22 tonnes de charge utile<br></br>• Poussoir 5 essieux thermique - 24 tonnes de charge utile</span></p>

                </div>
            </div>
            <div ref={accordionRef5} >
            <Accordion items={items5} clickindex={items.length - 1} onAccordionClick={handleAccordionClick}/>

            </div>
        </div>
        <div className={styles.right}>
            <div className={styles.divarrowr}>
            <div className={styles.arrow2}  onClick={() => {
                const rect = accordionRef7.current.getBoundingClientRect();
                window.scrollTo({ 
                    top: rect.top + window.scrollY,
                    behavior: 'smooth' 
                });
            }}></div>
                <div className={styles.texte}>
                    <p><span style={{fontWeight: '400'}}>Camion grue</span>PK 34002 SH Palfinger <br></br><span style={{fontSize: window.innerWidth <= 768 ? '3vw' : '1vw', fontWeight: '400', fontFamily: 'system-ui', marginTop: '10px'}}> • Capacité de levage - de 1.42 tonnes à 16 mètres<br></br>• Grappin - 400 litres<br></br>• Pince à bois ou enrochements <br></br>• Fourches à palettes <br></br>• Levage de containers, machines de chantier, bigs-bags et divers matériels. </span><span style={{fontSize: window.innerWidth <= 768 ? '2vw' : '1vw', fontWeight: '400', fontFamily: 'system-ui', marginLeft: '15rem', cursor: 'pointer'}} onClick={handleViewSpecs}>Voir spécs</span></p>
                </div>
            </div>
            <div ref={accordionRef6} >
            <Accordion items={items6} defaultActiveIndex={0} clickindex={activeIndex} onAccordionClick={handleAccordionClick} />
            </div>
        </div>
        <div className={styles.left}>
            <div className={styles.divarrowl}>
                <div className={styles.arrow2}  onClick={() => {
                     const rect = accordionRef8.current.getBoundingClientRect();
                     window.scrollTo({ 
                         top: rect.top + window.scrollY,
                         behavior: 'smooth' 
                     });
                }}></div>
                <div className={styles.texte}>
                    <p><span style={{fontWeight: '400'}}>Broyeur</span> Arjes impaktor 250</p>
                </div>
            </div>
            <div ref={accordionRef7} >
            <Accordion items={items7} clickindex={items7.length -1} onAccordionClick={handleAccordionClick}/>

            </div>
        </div>
        <div className={styles.right}>
            <div className={styles.divarrowr}>
                <div className={styles.arrow2} style={{transform: 'rotate(-45deg)'}} onClick={() => {
                     const rect = accordionRef1.current.getBoundingClientRect();
                     window.scrollTo({ 
                         top: rect.top + window.scrollY,
                         behavior: 'smooth' 
                     });
                }}></div>
                <div className={styles.texte}>
                    <p><span style={{fontWeight: '400'}}>Bennes</span> Un large choix de bennes de 10 à 30m³</p>
                </div>
            </div>
            <div ref={accordionRef8} >
            <Accordion items={items8} clickindex={0} onAccordionClick={handleAccordionClick}/>
            </div>
        </div>
        
    </section>
)
};
