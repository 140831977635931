import React from 'react'
import styles from './Contact.module.css'

export const Contact = () => {
  return (
    <div className={styles.contactAll}>
      <div style={{backgroundColor:'#6bd6326e', height:'2.5rem'}}>
      </div>
      <h1 className={styles.title}>Contactez-Nous</h1>
      <div className={styles.contact}>
        <div className={styles.contactGauche}>
          <div className={styles.adresse}>
          <h2>Transports Lopes SA</h2>
          <br></br>
          <p>Rte de Divonne 50B</p>
          <br></br>
          <p>1260 Nyon, Suisse</p>
          </div>
          <div className={styles.horaires}>
          <h2>Horaires</h2>
          <br></br>
          <p>Lundi - Vendredi: 7h30 - 18h30</p>
          <br></br>
          <p>Samedi: Fermé</p>
          <br></br>
          <p>Dimanche: Fermé</p>
          </div>
        </div>
        <span className={styles.line}></span>
        
        <div className={styles.contactDroite}>
          <div className={styles.contactInfo}>
            <div className={styles.bureau}>
              <h2>Contact</h2>
              <br></br>

              <p>Bureau</p>
              <br></br>
              <p>Tel: +41 21 601 42 11</p>
              <br></br>
              <p>
              E-mail: &nbsp;
                <a href="mailto:info@transportslopes.ch">info@transportslopes.ch</a>
              </p>
            </div>
            <br></br>


            <div className={styles.directeur}>
              <p>Directeur: M. LOPES Luis</p>
              <br></br>
              <p>Tel: +41 79 819 68 66</p>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.blanc}>
        <span></span>
      </div>
      <iframe 
        title="Google Maps"
        width="100%" 
        height="270" 
        frameborder="0" 
        marginheight="0" 
        marginwidth="0" 
        src="https://maps.google.com/maps?width=100%25&amp;height=300&amp;hl=en&amp;q=Google,Transports Lopes, Rte de Divonne 50B, 1260 Nyon&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed">
      </iframe>
    </div>

  )
}