import React, { useEffect, useRef, useState } from "react";
import styles from "./Galerie.module.css";
import { gsap } from "gsap";
import Modal from "react-modal";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

export const Galerie = () => {
  const imagesContext = require.context(
    "../../Assets/img/galerie",
    false,
    /\.(png|jpe?g|svg|mp4)$/
  );
  const images = imagesContext.keys().map(imagesContext);
  const ref = useRef(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  const openModal = (image) => {
    setSelectedImage(image);
    setModalIsOpen(true);
    document.body.style.overflow = "hidden";
  };

  const closeModal = () => {
    setModalIsOpen(false);
    document.body.style.overflow = "auto";
  };
  useEffect(() => {
    // Les éléments sont simplement référencés, mais aucun effet n'est appliqué
    const elements = ref.current.children;
  }, []);

  return (
        <body className={styles.body}>
          <section className={styles.section} ref={ref}>
            {images.map((img, index) => {
              if (img.endsWith(".mp4")) {
                return (
                  <video key={index} controls>
                    <source src={img} type="video/mp4" />
                  </video>
                );
              } else {
                return (
                  <div key={index} className={styles.div}>
                    <img
                      src={img}
                      onClick={() => openModal(img)}
                      className={`${styles.img} ${styles.hoverable}`}
                    />
                  </div>
                );
              }
            })}
          </section>
          <Modal
            className={styles.modal}
            isOpen={modalIsOpen}
            onRequestClose={closeModal}
            contentLabel="Image Modal"
          >
            <img src={selectedImage} className={styles.modalImage} />
            <button className={styles.closemodalbutton} onClick={closeModal}>Fermer</button>
          </Modal>
        </body>
  );
};

export default Galerie;
