import React, { useState, Suspense } from "react";
import LazyLoad from "react-lazyload";
import styles from "./Services.module.css";

export const Services = () => {
  const [isHovered1, setIsHovered1] = useState(false);
  const [isHovered2, setIsHovered2] = useState(false);
  const [isHovered3, setIsHovered3] = useState(false);
  const [isHovered4, setIsHovered4] = useState(false);
  const [isHovered5, setIsHovered5] = useState(false);
  const [isHovered6, setIsHovered6] = useState(false);
  const [isHovered7, setIsHovered7] = useState(false);
  const [isHovered8, setIsHovered8] = useState(false);
  const [loading, setLoading] = useState(true);

  return (
    <LazyLoad once>
      <Suspense fallback={<div>Loading...</div>}>
        <section className={styles.services}>
          <section className={styles.ServicesTop}>
            <div className={styles.leftSide}>
              <a
                href="/Contact"
                className={styles.link + " " + styles.link1}
                onMouseEnter={() => setIsHovered1(true)}
                onMouseLeave={() => setIsHovered1(false)}
              >
                <span className={styles.mask}>
                  <div className={styles.linkcontainer}>
                    <span className={styles.linktitle1 + " " + styles.title}>
                      Terrassements / Démolitions
                    </span>
                    <span className={styles.linktitle2G + " " + styles.title}>
                      Terrassements / Démolitions
                    </span>
                  </div>
                </span>
              </a>
            </div>
            <div className={styles.divG}></div>
            <div className={styles.dot1}>
              <div className={isHovered1 ? styles.dashH1 : styles.dash1}></div>
            </div>
            <div className={styles.dot2}>
              <div className={isHovered2 ? styles.dashH2 : styles.dash2}></div>
            </div>
            <div className={styles.divD}></div>
            <div className={styles.rightSide}>
              <a
                href="/Contact"
                className={styles.link + " " + styles.link2}
                onMouseEnter={() => setIsHovered2(true)}
                onMouseLeave={() => setIsHovered2(false)}
              >
                <span className={styles.mask2}>
                  <div className={styles.linkcontainer2}>
                    <span
                      className={
                        styles.linktitle1 +
                        " " +
                        styles.title +
                        " " +
                        styles.margin2
                      }
                    >
                      Enrobés
                    </span>
                    <span className={styles.linktitle2D + " " + styles.title}>
                      Enrobés
                    </span>
                  </div>
                </span>
              </a>
            </div>
          </section>

          <section className={styles.ServicesMid}>
            <div className={styles.leftSide}>
              <a
                href="/Contact"
                className={styles.link + " " + styles.link3}
                onMouseEnter={() => setIsHovered3(true)}
                onMouseLeave={() => setIsHovered3(false)}
              >
                <span className={styles.maskLil}>
                  <div className={styles.linkcontainerLil}>
                    <span
                      className={
                        styles.linktitleLil1 +
                        " " +
                        styles.titleLil +
                        " " +
                        styles.margin3
                      }
                    >
                      Services de camion grue
                    </span>
                    <span
                      className={styles.linktitleLil2G + " " + styles.titleLil}
                    >
                      Services de camion grue
                    </span>
                  </div>
                </span>
              </a>
              <a
                href="/Contact"
                className={styles.link + " " + styles.link4}
                onMouseEnter={() => setIsHovered4(true)}
                onMouseLeave={() => setIsHovered4(false)}
              >
                <span className={styles.maskLil}>
                  <div className={styles.linkcontainerLil}>
                    <span
                      className={
                        styles.linktitleLil1 +
                        " " +
                        styles.titleLil +
                        " " +
                        styles.margin4
                      }
                    >
                      Transports de chantier
                    </span>
                    <span
                      className={styles.linktitleLil2GA + " " + styles.titleLil}
                    >
                      Transports de chantier
                    </span>
                  </div>
                </span>
              </a>
              <a
                href="/Contact"
                className={styles.link + " " + styles.link5}
                onMouseEnter={() => setIsHovered5(true)}
                onMouseLeave={() => setIsHovered5(false)}
              >
                <span className={styles.maskLil}>
                  <div className={styles.linkcontainerLil}>
                    <span
                      className={
                        styles.linktitleLil1 +
                        " " +
                        styles.titleLil +
                        " " +
                        styles.margin5
                      }
                    >
                      Services de balayeuse
                    </span>
                    <span
                      className={styles.linktitleLil2GA + " " + styles.titleLil}
                    >
                      Services de balayeuse
                    </span>
                  </div>
                </span>
              </a>
            </div>

            <div className={styles.divG}></div>
            <div className={styles.dot3}>
              <div className={isHovered3 ? styles.dashH3 : styles.dash3}></div>
            </div>

            <div className={styles.dot4}>
              <div className={isHovered4 ? styles.dashH4 : styles.dash4}></div>
            </div>
            <div className={styles.dot5}>
              <div className={isHovered5 ? styles.dashH5 : styles.dash5}></div>
            </div>
            <div className={styles.dot6}>
              <div className={isHovered6 ? styles.dashH6 : styles.dash6}></div>
            </div>
            <div className={styles.dot7}>
              <div className={isHovered7 ? styles.dashH7 : styles.dash7}></div>
            </div>
            <div className={styles.dot8}>
              <div className={isHovered8 ? styles.dashH8 : styles.dash8}></div>
            </div>
            <div className={styles.divD}></div>

            <div className={styles.rightSide}>
              <a
                href="/Contact"
                className={styles.link + " " + styles.link6}
                onMouseEnter={() => setIsHovered6(true)}
                onMouseLeave={() => setIsHovered6(false)}
              >
                <span className={styles.maskLil}>
                  <div className={styles.linkcontainerLil}>
                    <span
                      className={
                        styles.linktitleLil1 +
                        " " +
                        styles.titleLil +
                        " " +
                        styles.margin6
                      }
                    >
                      Transport de béton
                    </span>
                    <span
                      className={styles.linktitleLil2D + " " + styles.titleLil}
                    >
                      Transport de béton
                    </span>
                  </div>
                </span>
              </a>
              <a
                href="/Contact"
                className={styles.link + " " + styles.link7}
                onMouseEnter={() => setIsHovered7(true)}
                onMouseLeave={() => setIsHovered7(false)}
              >
                <span className={styles.maskLil}>
                  <div className={styles.linkcontainerLil}>
                    <span
                      className={
                        styles.linktitleLil1 +
                        " " +
                        styles.titleLil +
                        " " +
                        styles.margin7
                      }
                    >
                      Services de bennes
                    </span>
                    <span
                      className={styles.linktitleLil2D + " " + styles.titleLil}
                    >
                      Services de bennes
                    </span>
                  </div>
                </span>
              </a>
              <a
                href="/Contact"
                className={styles.link + " " + styles.link8}
                onMouseEnter={() => setIsHovered8(true)}
                onMouseLeave={() => setIsHovered8(false)}
              >
                <span className={styles.maskLil}>
                  <div className={styles.linkcontainerLil}>
                    <span
                      className={
                        styles.linktitleLil1 +
                        " " +
                        styles.titleLil +
                        " " +
                        styles.margin8
                      }
                    >
                      Transports spéciaux
                    </span>
                    <span
                      className={styles.linktitleLil2DA + " " + styles.titleLil}
                    >
                      Transports spéciaux
                    </span>
                  </div>
                </span>
              </a>
            </div>
          </section>

          <section className={styles.servicesBot}>
            <div className={styles.greenU}>
              <div className={styles.greenL}></div>
              <div className={styles.greenR}></div>
            </div>
            <div className={styles.galerie}>
              <h1>Plus de photos de nos services dans la galerie.</h1>
              <a href="/Galerie" className={styles.button}>
                Voir la galerie
              </a>
            </div>
            <div className={styles.degra}></div>
          </section>
        </section>
      </Suspense>
    </LazyLoad>
  );
};
